<template>
  <div class="container min-height flex-column justify-content-spaceBetween align-items-center">
    <div class="width-100 flex-column justify-content-start align-items-center">
      <top :active="1"></top>
      <div class="container-box">
        <!--    菜单筛选区-->
        <!-- <div class="menu-box width-100">
          <div class="width-100 flex-row justify-content-start align-items-star cut-line">
            <div class="width-10 fs-mid fw-mid">版权分类</div>
            <div class="width-90 flex-row justify-content-start align-items-center flex-wrap">
              <el-button v-for="(item, index) in copyrightTypeList" :key="index" class="margin-b margin-right">
                <p
                  class="item fs-mid pointer"
                  :class="queryParams.copyrightTypeOn === item.value ? 'theme-blue' : 'darkgrey'"
                  @click="handleCopyrightTypeSelect(item.value)"
                >
                  {{ item.text }}
                </p>
              </el-button>
            </div>
          </div>

          <div class="width-100 flex-row justify-content-start align-items-star cut-line">
            <div class="width-10 fs-mid fw-mid">权力种类</div>
            <div class="width-90 flex-row justify-content-start align-items-center flex-wrap">
              <el-button v-for="(item, index) in authorizationTypeList" :key="index" class="margin-b margin-right">
                <p
                  class="item fs-mid pointer"
                  :class="queryParams.authorizationType === item.value ? 'theme-blue' : 'black'"
                  @click="handleAuthorizationTypeSelect(item.value)"
                >
                  {{ item.text }}
                </p>
              </el-button>
            </div>
          </div>

          <div class="width-100 flex-row justify-content-start align-items-star cut-line">
            <div class="width-10 fs-mid fw-mid">版权用途</div>
            <div class="width-90 flex-row justify-content-start align-items-center flex-wrap">
              <el-button v-for="(item, index) in copyrightUsageTypeList" :key="index" class="margin-b margin-right">
                <p
                  class="item fs-mid pointer"
                  :class="queryParams.copyrightUsage === item.value ? 'theme-blue' : 'black'"
                  @click="handleCopyrightUsageTypeSelect(item.value)"
                >
                  {{ item.text }}
                </p>
              </el-button>
            </div>
          </div>
        </div> -->
        <!--      列表区域-->
        <div class="width-100 list-box flex-row justify-content-spaceBetween align-items-center">
          <div class="width-60">
            <el-menu :default-active="sortIndex" class="width-90" mode="horizontal">
              <el-menu-item @click="handleSortClick(item)" v-for="(item, index) in sortList" :key="index" :index="item.index"
                >{{ item.text }}
                <i v-if="item.icon" :class="item.icon"></i>
              </el-menu-item>
            </el-menu>
          </div>
          <div class="width-40 search-box">
            <el-input placeholder="输入关键字搜索 " v-model="queryParams.params.searchText">
              <el-button @click="getCopyrightData" slot="append">搜索</el-button>
            </el-input>
          </div>
        </div>
        <div class="width-100 list-item flex-row justify-content-start align-items-star flex-wrap">
          <el-row :gutter="30" class="width-100" style="margin-left: 0px">
            <el-col :span="6" v-for="(item, index) in list" :key="index">
              <div class="item bg-white pointer" @click="$router.push({ path: '/copyrightWorksDisplayDetail', query: { id: item.id } })">
                <van-image class="image" :src="imagePrefix + item.worksImage" width="100%" fit="contain"></van-image>
                <p class="padding-Tb-10 padding-lr fs-mid black width-100 cut-text">{{ item.title }}</p>
                <p class="padding-lr fs-mid darkgrey width-100 cut-text opacity-8">{{ item.worksContactName }}</p>
                <p class="padding-lr margin-ts fs-mid theme-blue width-100 cut-text">
                  {{ item.worksPrice === '0' ? '价格面议' : '￥' + item.worksPrice }}
                </p>
              </div>
            </el-col>
          </el-row>
        </div>
        <div style="width: 100%; text-align: center">
          <el-empty v-if="list.length < 1" description="暂无登记信息" :image-size="200" style="text-align: center"></el-empty>
        </div>
        <!--      分页-->
        <div class="pagination width-100 textAlign-c">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="queryParams.pageNum"
            :page-sizes="pageSizeProfile"
            :page-size="queryParams.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <bottom></bottom>
  </div>
</template>
<script>
import top from '../components/top';
import bottom from '../components/footer';
import { getDict } from '@/api/dict';
import { worksList } from '@/api/tranCopyrightWorks';
// import { registrationList } from '@/api/tranCopyrightRegistration';

export default {
  name: 'copyrightDisplay',
  components: {
    top,
    bottom
  },
  data() {
    return {
      total: null,
      pageSizeProfile: [20, 40, 100, 200],
      list: [],
      sortIndex: '0',
      imagePrefix: process.env.VUE_APP_IMAGE_PREFIX,
      sortList: [
        {
          index: '0',
          text: '综合排序',
          sortRule: null
        },
        {
          index: '1',
          text: '最新',
          sortRule: 'createTime'
        },
        {
          index: '2',
          text: '价格',
          sortRule: 'prizeDown',
          icon: 'el-icon-sort'
        }
      ],
      descSortFlag: true,
      queryParams: {
        title: null,
        copyrightTypeOn: null,
        authorizationType: null,
        copyrightUsage: null,
        pageNum: 1,
        pageSize: 20,
        params: {
          searchText: null,
          sortRule: null
        }
      },
      // 版权分类
      copyrightTypeList: [
        {
          text: '全部',
          value: null
        }
      ],
      // 权力种类
      authorizationTypeList: [
        {
          text: '全部',
          value: null
        }
      ],
      // 版权用途
      copyrightUsageTypeList: [
        {
          text: '全部',
          value: null
        }
      ]
    };
  },
  created() {},
  async mounted() {
    if (this.$route.query.findName) {
      this.queryParams.params.searchText = this.$route.query.findName;
    }
    await this.setDist();
    this.getCopyrightData();
  },
  methods: {
    async setDist() {
      return Promise.all([getDict('bqsb_copyright_type'), getDict('tran_authorization_type'), getDict('tran_copyright_usage')]).then((rows) => {
        this.copyrightTypeList.push(...rows[0]);
        this.authorizationTypeList.push(...rows[1]);
        this.copyrightUsageTypeList.push(...rows[2]);
      });
    },
    getCopyrightData() {
      this.list = [];
      worksList(this.queryParams).then((res) => {
        this.total = res.total;
        this.list = res.rows;
      });
    },
    handleSortClick(item) {
      console.log(item);
      if (item.text === '价格') {
        if (this.descSortFlag) {
          this.sortList[2].icon = 'el-icon-sort-down';
          this.sortList[2].sortRule = 'prizeDown';
        } else {
          this.sortList[2].icon = 'el-icon-sort-up';
          this.sortList[2].sortRule = 'prizeUp';
        }
        this.descSortFlag = !this.descSortFlag;
      }

      this.queryParams.params.sortRule = item.sortRule;
      this.getCopyrightData();
    },
    handleCopyrightTypeSelect(val) {
      this.queryParams.copyrightTypeOn = val;
      this.getCopyrightData();
    },
    handleAuthorizationTypeSelect(val) {
      this.queryParams.authorizationType = val;
      this.getCopyrightData();
    },
    handleCopyrightUsageTypeSelect(val) {
      this.queryParams.copyrightUsage = val;
      this.getCopyrightData();
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.queryParams.pageSize = val;
      this.getCopyrightData();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.queryParams.pageNum = val;
      this.getCopyrightData();
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  background: #f3f9ff;

  .menu-box {
    margin: 40px 0;
    padding: 40px 40px 10px;
    background: white;
    border-radius: 10px;

    .cut-line {
      margin-bottom: 20px;
      padding-bottom: 0px;
      border-bottom: 1px solid #eeeeee;
    }

    .margin-right {
      margin-right: 80px;
      margin-bottom: 0px;
    }

    .padding-bottom {
      padding-bottom: 20px;
    }

    .el-button:focus,
    .el-button:hover {
      border: none !important;
      background-color: white;
    }

    .el-button {
      border: none !important;
      padding: 0 !important;
    }

    .padding-0 {
      padding: 0 !important;
    }
  }

  .list-box {
    border-bottom: 1px solid #ececec;
    margin-bottom: 40px;

    .el-button {
      background: #3f96ff;
      border-radius: 50px;
      color: white;
      width: 100px;
    }
  }

  .list-item {
    background: #f3f9ff;

    .item {
      width: 100%;
      height: 360px;
      box-shadow: 0 0 8px rgba(133, 188, 240, 0.25);

      border-radius: 10px !important;
      overflow: hidden;
    }

    .image {
      height: 230px;
    }
  }

  .el-row {
    margin-bottom: 20px !important;

    &:last-child {
      margin-bottom: 0 !important;
    }
  }

  .el-col {
    margin-bottom: 30px;
  }

  .pagination {
    margin: 60px 0 80px;
  }
}
</style>
<style scoped>
.el-popover {
  border: none;
  font-size: 16px !important;
}
.search-box .el-input__inner {
  border: none !important;
  line-height: 3rem !important;
  height: 3rem !important;
  padding: 0 30px;
  border-radius: 50px 0 0 50px;
  color: #333333;
  font-size: 18px;
}
.search-box {
  padding: 5px;
  background-color: white;
  border-radius: 3.125rem;
}

/deep/.el-input-group__append {
  background: white !important;
  border: none !important;
  border-radius: 0 50px 50px 0;
  width: 105px;
}
/deep/.el-button + .el-button,
.el-checkbox.is-bordered + .el-checkbox.is-bordered {
  margin-left: 0;
}

.el-menu.el-menu--horizontal {
  border-bottom: none;
  background: rgba(0, 0, 0, 0);
}

.el-menu--horizontal > .el-menu-item.is-active {
  color: #3f96ff !important;
  font-weight: bold;
}

.el-menu--horizontal > .el-menu-item.is-active:hover,
.el-menu--horizontal > .el-menu-item.is-active,
.el-menu--horizontal > .el-menu-item:hover {
  background: rgba(0, 0, 0, 0) !important;
}

.el-menu.el-menu--horizontal li {
  font-size: 18px;
  width: 120px;
  text-align: center;
  color: #333333;
}

.el-popover {
  min-width: 0 !important;
  padding: 0 10px !important;
  color: #3f96ff;
  line-height: 2rem;
  box-shadow: 0 0.125rem 0.75rem 0 rgba(0, 0, 0, 0.1);
}
</style>
