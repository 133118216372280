import request from '@/util/request';
// import * as _ from 'lodash';

const getSystemDict = (name) => {
    return request({
        url: `/system/dict/data/type/${name}`,
        method: 'get',
        params: {}
    });
};

export async function getDict(name) {
    const dist = await getSystemDict(name);
    if (dist.code !== 200) return [];
    return dist.data.map((row) => {
        return {
            text: row.dictLabel,
            value: Number(row.dictValue)
        };
    });
}

// 查询作品创作类别
export function copyrightCreationTypeDict(query) {
    return request({
        url: '/system/dict/data/type/bqsb_copyright_creation_type',
        method: 'get',
        params: query
    });
}

// 查询作品创作类别
export function ownerShipWayTypeDict(query) {
    return request({
        url: '/system/dict/data/type/bqsb_copyright_owner_ship_way_type',
        method: 'get',
        params: query
    });
}

// 查询作品创作类别
export function creationTypeDict(query) {
    return request({
        url: '/system/dict/data/type/bqsb_creation_type',
        method: 'get',
        params: query
    });
}

// 查询作品创作类别
export function copyrightTypeDict(query) {
    return request({
        url: '/system/dict/data/type/bqsb_copyright_type',
        method: 'get',
        params: query
    });
}

// 版权纠纷类型
export function bqsbDisputeTypeDict(query) {
    return request({
        url: '/system/dict/data/type/bqsb_dispute_type',
        method: 'get',
        params: query
    });
}
