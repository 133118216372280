import request from '@/util/request';

export function postTranCopyrightWorks(data) {
  return request({
    url: `/tran/tranCopyrightWorks`,
    method: 'post',
    data
  });
}


// 免登录 获取详情
export function registrationInfo(id, params) {
  return request({
    url: `/tran/tranCopyrightWorks/ua/${id}`,
    method: 'get',
    params,
  });
}

export function putTranCopyrightWorks(data) {
  return request({
    url: `/tran/tranCopyrightWorks`,
    method: 'put',
    data
  });
}

// 免登录 获取列表
export function worksList(params) {
  return request({
    url: `/tran/tranCopyrightWorks/ua/list`,
    method: 'get',
    params: params
  });
}

// 我的获取列表
export function myWorksList(params) {
  return request({
    url: `/tran/tranCopyrightWorks/list`,
    method: 'get',
    params: params
  });
}

// 删除
export function deleteTranCopyrightWorks(ids) {
  return request({
    url: `/tran/tranCopyrightWorks/${ids}`,
    method: 'delete'
  });
}
// 上架
export function publicCopyrightWorks(id) {
  return request({
    url: `/tran/tranCopyrightWorks/${id}/public`,
    method: 'put'
  });
}
// 下架
export function unpublicCopyrightWorks(id) {
  return request({
    url: `/tran/tranCopyrightWorks/${id}/unPublic`,
    method: 'put'
  });
}
// 想要
export function wantBuy(id) {
  return request({
    url: `/tran/tranCopyrightWorks/${id}/wantBuy`,
    method: 'post'
  });
}